import { click } from "ol/events/condition";

import {
  MEASURE_DATA_TYPE,
  PANEL_DATA_TYPE,
  SEGMENT_DATA_TYPE,
  THERMAL_EXPANSION_DATA_TYPE,
} from "../../../../da/map/data-types";
import {
  SELECT_TYPE_PANELS,
  SELECT_TYPE_SEGMENTS,
  SELECT_TYPE_MEASURES,
  SELECT_TYPE_THERMAL_EXPANSIONS,
} from "../../../../da/layout-editor/helpers/toolbar-constants";
import PanelsDeleter from "../../modification-helpers/panels/deleter";
import SegmentsDeleter from "../../modification-helpers/segments/deleter";
import ThermalExpansionsDeleter from "../../modification-helpers/thermal-expansions/deleter";
import SegmentsNudger from "../../modification-helpers/segments/nudger";
import SegmentsAligner from "../../modification-helpers/segments/aligner";
import SegmentsRowsAndColumnsAdder from "../../modification-helpers/segments/rows-and-columns-adder";
import PanelsNudgerVertical from "../../modification-helpers/panels/nudger/vertical";
import PanelsNudgerHorizontal from "../../modification-helpers/panels/nudger/horizontal";
import Base from "./base";
import { selectedPanelsStyle } from "../../styles/zone-panels";
import { selectedSegmentsStyle } from "../../styles/segments";
import { selectMeasureStyle } from "../../../../da/map/styles/measures";
import { errorAlertDialog } from "../../../../controllers/components/ir_dialog/helper";
import ThermalExpansionAdder from "../../modification-helpers/thermal-expansions/adder";
import { selectThermalExpansionStyle } from "../../styles/thermal-expansions";
import SegmentsAdjoiningAligner from "../../modification-helpers/segments/adjoining-aligner";
import SegmentsMerger from "../../modification-helpers/segments/merger";

export default class Arrays extends Base {
  constructor(controller, selectType) {
    super(controller);
    this.setSelectType(selectType);
  }

  add() {
    if (this.currentSelectInteraction) {
      if (!this.currentDragBoxInteraction && !this.isMeasureSelectType) this.addDragBoxInteraction();

      return;
    }

    super.add();
    if (!this.isMeasureSelectType) this.addDragBoxInteraction();

    this.selectionCollection.on(["add", "remove"], (collectionEvent) => {
      const { element: feature, type: addRemove } = collectionEvent;

      if (feature.get("dataType") === SEGMENT_DATA_TYPE) {
        const selectionArray = this.selectionCollection.getArray();
        selectionArray.forEach((feature, i) => {
          if (i === 0 && selectionArray.length > 1) {
            feature.set("anchorSelection", true);
          } else {
            feature.unset("anchorSelection");
          }
        });
      }

      const dataType = feature.get("dataType");
      const select = addRemove === "add";
      if (dataType === PANEL_DATA_TYPE) {
        this.#selectDeselectRails(select, feature.get("segmentUuid"));
      } else if (dataType === SEGMENT_DATA_TYPE) {
        this.#selectDeselectRails(select, feature.get("uuid"));
      }

      this.controller.statusItemSelectionsTarget.innerText = this.selectionCollection.getLength();
    });
  }

  #selectDeselectRails(select, segmentUuid) {
    let segment = this.project.segments.find((s) => s.uuid === segmentUuid);

    if (!segment) return;
    if (!segment.railed) segment = segment.railedParent;

    const railFeatures = this.mapManager.railsVectorSource
      .getFeatures()
      .filter((rf) => rf.get("segmentUuid") === segment.uuid);

    railFeatures.forEach((rf) => {
      if (select) {
        rf.set("selected", true);
      } else {
        rf.set("selected", false);
      }
    });
  }

  selectClickResetInteractionManagers() {
    if (this.isMeasureSelectType) {
      // The translate interaction has to be before the modify, or modify doesn't work
      this.controller.translateInteractionManager.add();
      this.controller.modifyInteractionManager.add();
    }
  }

  selectStyle = (feature) => {
    if (feature.get("dataType") === PANEL_DATA_TYPE) {
      return selectedPanelsStyle(feature, this.map, this.controller.project.is716Or722);
    } else if (feature.get("dataType") === SEGMENT_DATA_TYPE) {
      return selectedSegmentsStyle(feature, this.controller, this.map);
    } else if (feature.get("dataType") === MEASURE_DATA_TYPE) {
      return selectMeasureStyle(feature, this.controller, this.map);
    } else if (feature.get("dataType") === THERMAL_EXPANSION_DATA_TYPE) {
      return selectThermalExpansionStyle(feature);
    }
  };

  setSelectType(selectType) {
    const previousSelectType = this.selectType;
    this.selectType = selectType;
    let autoSelectSegmentUuids = [];

    if (this.isMeasureSelectType || this.isThermalExpansionSelectType) {
      this.clearDragBoxInteraction();
    } else {
      if (
        this.isSegmentSelectType &&
        previousSelectType === SELECT_TYPE_PANELS &&
        this.currentSelectInteraction &&
        this.selectionCollection.getLength() > 0
      ) {
        autoSelectSegmentUuids = [
          ...new Set(
            this.selectedFeatures.map((panelFeature) => {
              return panelFeature.get("segmentUuid");
            }),
          ),
        ];
      }
      if (this.controller.measureInteractionManager) {
        this.controller.measureInteractionManager.clearDragHandles();
      }
      this.mapManager.adjustPanelSegmentZIndexesForSelectType(selectType);
      if (!this.currentDragBoxInteraction) this.addDragBoxInteraction();
    }

    if (this.currentSelectInteraction) {
      this.selectionCollection.clear();

      autoSelectSegmentUuids.forEach((segmentUuid) => {
        const segmentFeature = this.mapManager.segmentsVectorSource
          .getFeatures()
          .find((sf) => sf.get("uuid") === segmentUuid);
        if (!segmentFeature) return;

        this.selectionCollection.push(segmentFeature);
      });
    }
  }

  get isPanelSelectType() {
    return this.selectType === SELECT_TYPE_PANELS;
  }

  get isSegmentSelectType() {
    return this.selectType === SELECT_TYPE_SEGMENTS;
  }

  get isMeasureSelectType() {
    return this.selectType === SELECT_TYPE_MEASURES;
  }

  get isThermalExpansionSelectType() {
    return this.selectType === SELECT_TYPE_THERMAL_EXPANSIONS;
  }

  isSegmentSelected(segmentFeature) {
    return this.selectedFeatures.find((sf) => sf.get("uuid") === segmentFeature.get("uuid"));
  }

  nudge(direction, inches) {
    if (!this.currentSelectInteraction || this.selectionCollection.getLength() === 0) return;

    this.mapManager.dispatchBeforeMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#nudge[${direction}]`,
    });

    const nudgerClass = this.nudgerClass(direction);
    new nudgerClass({
      controller: this.controller,
      selectionCollection: this.selectionCollection,
      nudgeDirection: direction,
      nudgeDistance: inches,
    }).nudge();

    this.controller.markDirty();
    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#nudge[${direction}]`,
    });
  }

  nudgerClass(direction) {
    if (this.isSegmentSelectType) return SegmentsNudger;
    if (["up", "down"].includes(direction)) return PanelsNudgerVertical;
    return PanelsNudgerHorizontal;
  }

  align(alignTo) {
    if (!this.isSegmentSelectType) return;

    if (this.selectionCollection.getLength() < 2) {
      errorAlertDialog("You need to select at least two segments to align");
      return;
    }

    this.mapManager.dispatchBeforeMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#align[${alignTo}]`,
    });

    new SegmentsAligner({
      controller: this.controller,
      selectionCollection: this.selectionCollection,
      alignTo,
    }).align();

    this.controller.markDirty();
    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#align[${alignTo}]`,
    });
  }

  adjoiningAlign(direction, adjoinmentType) {
    if (this.selectionCollection.getLength() !== 2) {
      errorAlertDialog("You need to select exactly two segments to carry out an adjoining alignment");
      return;
    }

    const aligner = new SegmentsAdjoiningAligner({
      controller: this.controller,
      selectionCollection: this.selectionCollection,
      direction,
      adjoinmentType,
    });

    if (!aligner.areSegmentsAlignable()) {
      errorAlertDialog(aligner.notAlignableMessage);
      return;
    }

    aligner.align();

    this.controller.markDirty();
    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#adjoiningAlign[${direction}]`,
    });
  }

  merge(direction) {
    if (this.selectionCollection.getLength() !== 2) {
      errorAlertDialog("You need to select exactly two segments to carry out a merge");
      return;
    }

    const merger = new SegmentsMerger({
      controller: this.controller,
      selectionCollection: this.selectionCollection,
      direction,
    });

    if (!merger.areSegmentsMergeable) {
      errorAlertDialog(merger.notMergeableMessage);
      return;
    }

    merger.merge();

    this.controller.markDirty();
    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#merge[${direction}]`,
    });
  }

  removeSelectedFeatures(event) {
    if (!this.currentSelectInteraction) return;

    const { controller, selectionCollection } = this;

    if (selectionCollection.getLength() === 0) return;

    this.mapManager.dispatchBeforeMapFeaturesRendering({
      calledFrom: "ArraysSelectInteractionManager#removeSelectedFeatures",
    });

    if (this.isSegmentSelectType) {
      new SegmentsDeleter({ controller, selectionCollection }).delete();
    }

    if (this.isPanelSelectType) {
      const panelsDeleter = new PanelsDeleter({ controller, selectionCollection });
      const deleteType = event.shiftKey ? "railed" : "unrailed";
      panelsDeleter.delete(deleteType);
    }

    if (this.isMeasureSelectType) {
      this.measureSelectManager.removeSelectedMeasures();
    }

    if (this.isThermalExpansionSelectType) {
      new ThermalExpansionsDeleter({ controller }).delete();
    }

    const modifiesSaveableState = !this.isMeasureSelectType;
    if (modifiesSaveableState) this.controller.markDirty();

    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "ArraysSelectInteractionManager#removeSelectedFeatures",
    });
  }

  addToSegment(side) {
    if (!this.isSegmentSelectType) return;

    if (this.selectionCollection.getLength() === 0) {
      errorAlertDialog("You need to select at least one segment to add to");
      return;
    }

    this.mapManager.dispatchBeforeMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#addToSegment[${side}]`,
    });

    new SegmentsRowsAndColumnsAdder({
      controller: this.controller,
      selectionCollection: this.selectionCollection,
      side,
    }).add();

    this.controller.markDirty();
    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: `ArraysSelectInteractionManager#addToSegment[${side}]`,
    });
  }

  filterSelects = (feature, _layer) => {
    if (this.isMeasureSelectType && feature.get("dataType") === MEASURE_DATA_TYPE) return true;
    if (this.isPanelSelectType && feature.get("dataType") === PANEL_DATA_TYPE) return true;
    if (this.isSegmentSelectType && feature.get("dataType") === SEGMENT_DATA_TYPE) return true;
    if (this.isThermalExpansionSelectType && feature.get("dataType") === THERMAL_EXPANSION_DATA_TYPE) return true;
    return false;
  };

  selectCondition = (event) => {
    if (event.type !== "pointermove") {
      if (event.originalEvent.altKey) {
        // This allows you to click with the alt key pressed to remove single items from the selection.
        // This is consistent with the UX we have allowing you to hold alt and drag a marquee to remove
        // items under it from a selection
        this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
          this.selectionCollection.remove(feature);
        });
        return false;
      }
    }
    return click(event);
  };

  get selectionVectorSource() {
    let vectorSource;
    if (this.isPanelSelectType) {
      vectorSource = this.mapManager.panelsVectorSource;
    } else if (this.isSegmentSelectType) {
      vectorSource = this.mapManager.segmentsVectorSource;
    } else {
      vectorSource = this.mapManager.measuresVectorSource;
    }
    return vectorSource;
  }

  addThermalExpansion(direction) {
    if (!this.#hasSelectedPanels) {
      errorAlertDialog("Please select at least one panel to insert a thermal expansion next to.");
    } else if (this.#hasSelectedPanelsInMoreThanOneSegment) {
      errorAlertDialog("You can only add thermal expansions to one segment at a time.");
    } else if (this.#hasSelectedPanelsInMoreThanOneColumn) {
      errorAlertDialog("Please select only panels in the same segment column to insert a thermal expansion next to.");
    } else if (this.#addingToEndOfRailGroup(direction)) {
      errorAlertDialog("You can not add a thermal expansion to the end of a segment rail group.");
    } else if (this.#addingThermalExpansionWhereOneAlreadyExists(direction)) {
      errorAlertDialog("You can only have one thermal expansion between two segments");
    } else {
      this.mapManager.dispatchBeforeMapFeaturesRendering({
        calledFrom: `ArraysSelectInteractionManager#addThermalExpansion[${direction}]`,
      });

      new ThermalExpansionAdder({
        controller: this.controller,
        selectionCollection: this.selectionCollection,
        direction,
        sourcePanelFeature: this.#selectedPanels[0],
      }).add();

      this.controller.markDirty();
      this.mapManager.dispatchAfterMapFeaturesRendering({
        calledFrom: `ArraysSelectInteractionManager#addThermalExpansion[${direction}]`,
      });
    }
  }

  get #hasSelectedPanels() {
    return this.#selectedPanels.length > 0;
  }

  get #hasSelectedPanelsInMoreThanOneSegment() {
    return [...new Set(this.#selectedPanels.map((pf) => pf.get("segmentUuid")))].length > 1;
  }

  get #hasSelectedPanelsInMoreThanOneColumn() {
    return [...new Set(this.#selectedPanels.map((pf) => pf.get("model").column))].length > 1;
  }

  #addingToEndOfRailGroup(direction) {
    const panelFeature = this.#selectedPanels[0];
    const panel = panelFeature.get("model");
    const segment = panel.segment;
    if (direction === "left" && segment.railed && panel.column === 1) return true;

    const railedGroup = segment.railedGroup;
    const isLastSegmentInRailGroup = railedGroup[railedGroup.length - 1] === segment;
    return direction === "right" && isLastSegmentInRailGroup && panel.column === segment.columns;
  }

  get #selectedPanels() {
    return this.selectedFeatures.filter((sf) => sf.get("dataType") === PANEL_DATA_TYPE);
  }

  #addingThermalExpansionWhereOneAlreadyExists(direction) {
    const panelFeature = this.#selectedPanels[0];
    const panel = panelFeature.get("model");
    const segment = panel.segment;
    const railedGroup = segment.railedGroup;
    const railedParent = railedGroup[0];
    const thermalExpansions = railedParent.thermalExpansions;

    if (thermalExpansions.length === 0) return false;

    if (segment.columns > 1) {
      if (direction === "right" && panel.column !== segment.columns) return false;
      if (direction === "left" && panel.column !== 1) return false;
    }

    let segment1;
    let segment2;
    if (direction === "left") {
      segment1 = segment.previousRailGroupSegment;
      segment2 = segment;
    } else {
      segment1 = segment;
      segment2 = segment.nextRailGroupSegment;
    }

    const existingThermalExpansionFoundInGap = thermalExpansions.find((te) => {
      return this.mapModelSynchronizer.isThermalExpansionBetweenSegments(te, segment1, segment2);
    });

    return existingThermalExpansionFoundInGap;
  }
}
