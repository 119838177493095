import { click } from "ol/events/condition";
import { SELECT_TYPE_MEASURES, SELECT_TYPE_SETBACK } from "../../../../da/layout-editor/helpers/toolbar-constants";
import { MEASURE_DATA_TYPE, SETBACK_DATA_TYPE } from "../../../../da/map/data-types";
import DaBase from "../../../../da/map/interaction-managers/select/base";

export default class Setbacks extends DaBase {
  constructor(controller, selectType) {
    super(controller);
    this.setSelectType(selectType);
  }

  add() {
    super.add();

    this.selectionCollection.on("add", this.deselectFeaturesOfOtherTypes);
  }

  filterSelects = (feature, _layer) => {
    if (this.isSetbackSelectType) {
      return feature.get("dataType") === SETBACK_DATA_TYPE;
    } else if (this.isMeasureSelectType) {
      return feature.get("dataType") === MEASURE_DATA_TYPE;
    }
  };

  get isSetbackSelectType() {
    return this.selectType === SELECT_TYPE_SETBACK;
  }

  get isMeasureSelectType() {
    return this.selectType === SELECT_TYPE_MEASURES;
  }

  setSelectType(selectType) {
    this.selectType = selectType;

    if (this.currentSelectInteraction) this.selectionCollection.clear();

    if (!this.isMeasureSelectType && this.controller.measureInteractionManager) {
      this.controller.measureInteractionManager.clearDragHandles();
    }
  }

  selectCondition = (event) => {
    if (this.isSetbackSelectType) {
      return false;
    } else {
      return click(event);
    }
  };

  // This helps to prevent translation of the setback.  Setbacks aren't
  // directly translatable, but there's an edge case where a user can
  // multi select the setback and a measure and then translate the measure,
  // which brings the setback along with it.  This prevents that by not
  // allowing both types to be selected at the same time.
  deselectFeaturesOfOtherTypes = (event) => {
    if (!event.element) return;

    let featuresToRemove;
    const selectedDataType = event.element.get("dataType");
    if (selectedDataType === MEASURE_DATA_TYPE) {
      featuresToRemove = this.mapManager.setbacksVectorSource.getFeatures();
    } else if (selectedDataType == SETBACK_DATA_TYPE) {
      this.measureSelectManager.removeAllDragHandles();
      featuresToRemove = this.mapManager.measuresVectorSource.getFeatures();
    }

    if (featuresToRemove) featuresToRemove.forEach((feature) => this.selectionCollection.remove(feature));
  };

  selectClickResetInteractionManagers() {
    this.controller.translateInteractionManager.add();
    this.controller.modifyInteractionManager.add();
  }

  removeSelectedShapes = () => {
    this.baseRemoveSelectedShapes();
  };
}
