import { Draw } from "ol/interaction";

import { measureDrawStyle } from "../../styles/measures";
import { MEASURE_DATA_TYPE, MEASURE_DRAG_HANDLE_DATA_TYPE } from "../../data-types";
import { calculateMeasureSlopeAdjustedDistance } from "./helpers";

export default class Base {
  constructor(controller) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;
    this.measuresVectorSource = this.mapManager.measuresVectorSource;

    this.measureBtnTarget = controller.measureBtnTarget;
  }

  add() {
    if (this.currentMeasureInteraction) return;

    this.clearCurrentInteraction();

    this.currentMeasureInteraction = new Draw({
      source: this.measuresVectorSource,
      type: "LineString",
      maxPoints: 2,
      style: (feature) => measureDrawStyle(feature, this.map),
    });

    this.map.addInteraction(this.currentMeasureInteraction);
    this.currentMeasureInteraction.on("drawend", this.onDrawEnd);
    this.controller.snapInteractionManager.refresh();
  }

  remove() {
    if (!this.currentMeasureInteraction) return;

    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (this.currentMeasureInteraction) {
      this.map.removeInteraction(this.currentMeasureInteraction);
      delete this.currentMeasureInteraction;
      this.currentMeasureInteraction = undefined;
    }
  }

  onDrawEnd = (event) => {
    const measureFeature = event.feature;

    measureFeature.set("dataType", MEASURE_DATA_TYPE);
    measureFeature.set("slopeAdjustedDistance", calculateMeasureSlopeAdjustedDistance(measureFeature, this.controller));
  };

  clearDragHandles() {
    const vectorSource = this.mapManager.measuresVectorSource;
    vectorSource.getFeatures().forEach((feature) => {
      if (feature.get("dataType") === MEASURE_DRAG_HANDLE_DATA_TYPE) {
        vectorSource.removeFeature(feature);
      }
    });
  }

  finish() {
    if (!this.currentMeasureInteraction) return;

    this.currentMeasureInteraction.finishDrawing();

    const drawingSource = this.mapManager.measuresVectorSource;
    const features = drawingSource.getFeatures();
    const lastFeature = features[features.length - 1];
    drawingSource.removeFeature(lastFeature);
  }
}
