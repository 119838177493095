import { fromLonLat } from "ol/proj";
import Circle from "ol/geom/Circle";
import Feature from "ol/Feature";

import { MEASURE_DATA_TYPE, MEASURE_DRAG_HANDLE_DATA_TYPE } from "../../data-types";
import { calculateUnitPerpendicularEdgeVectorLatLng, METERS_TO_INCHES } from "../../ol-geometry";

export default class MeasureSelectManager {
  constructor(map, measuresVectorSource, selectInteractionManager) {
    this.map = map;
    this.measuresVectorSource = measuresVectorSource;
    this.selectInteractionManager = selectInteractionManager;
  }

  removeSelectedMeasures() {
    this.currentSelectInteraction.getFeatures().forEach((feature) => {
      if ([MEASURE_DATA_TYPE, MEASURE_DRAG_HANDLE_DATA_TYPE].includes(feature.get("dataType"))) {
        this.measuresVectorSource.removeFeature(feature);
      }
    });
  }

  removeAllDragHandles() {
    const dragHandles = this.measuresVectorSource
      .getFeatures()
      .filter((feature) => feature.get("dataType") === MEASURE_DRAG_HANDLE_DATA_TYPE);
    this.removeDragHandles(dragHandles);
  }

  removeDragHandles(dragHandles) {
    dragHandles.forEach((dragHandle) => {
      if (this.measuresVectorSource.hasFeature(dragHandle)) {
        this.measuresVectorSource.removeFeature(dragHandle);
      }
      this.currentSelectInteraction.getFeatures().remove(dragHandle);
    });
  }

  addDragHandle(feature) {
    const [startPoint, endPoint] = feature.getGeometry().getCoordinates();
    const [midPointLatLng, unitPerpendicularEdgeVectorLatLng, _unitEdgeVectorLatLng] =
      calculateUnitPerpendicularEdgeVectorLatLng(startPoint, endPoint); // [coordinate, inches]

    const resolution = this.map.getView().getResolution(); // meters per pixel

    const arrowDistancePixels = 25;
    const arrowDistanceInches = arrowDistancePixels * resolution * METERS_TO_INCHES;

    const circleRadiusPixels = 15;
    const circleRadiusMeters = circleRadiusPixels * resolution;

    const arrowHeadLatLon = midPointLatLng.plus(unitPerpendicularEdgeVectorLatLng.times(arrowDistanceInches));
    const dragHandleOneCoordinates = fromLonLat(arrowHeadLatLon.toLonLat);

    this.addDragFeatures(feature, [dragHandleOneCoordinates], circleRadiusMeters);
  }

  addDragFeatures(measureFeature, dragHandleCoordinates, circleRadiusMeters) {
    const dragFeatures = dragHandleCoordinates.map((coordinate) => {
      const geometry = new Circle(coordinate, circleRadiusMeters);
      const dragFeature = new Feature({ geometry });
      dragFeature.set("dataType", MEASURE_DRAG_HANDLE_DATA_TYPE);
      dragFeature.set("measure", measureFeature);
      this.measuresVectorSource.addFeature(dragFeature);
      return dragFeature;
    });

    measureFeature.set("dragHandles", dragFeatures);

    // And select it; for some reason this gets auto de-selected unless we wait a bit
    setTimeout(() => {
      dragFeatures.forEach((dragFeature) => {
        this.currentSelectInteraction.getFeatures().push(dragFeature);
      });
    }, 15);
  }

  get currentSelectInteraction() {
    return this.selectInteractionManager.currentSelectInteraction;
  }

  refreshDragHandles() {
    if (!this.currentSelectInteraction) return;

    this.removeAllDragHandles();
    const selectedFeatures = this.currentSelectInteraction.getFeatures();

    selectedFeatures.forEach((f) => {
      if (f.get("dataType") === MEASURE_DRAG_HANDLE_DATA_TYPE) {
        selectedFeatures.remove(f);
      }
    });

    selectedFeatures.forEach((f) => {
      if (f.get("dataType") === MEASURE_DATA_TYPE) {
        this.addDragHandle(f);
      }
    });
  }
}
