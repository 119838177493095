import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import { addOptionsToSelect } from "../../helpers/form";

export default class extends Controller {
  static targets = [
    "customEwPierSpanFieldContainer",
    "rowsField",
    "diagonalBracingCheckbox",
    "maxSpanDisplay",
    "railOptionsField",
    "railOptionsWarning",
    "railCustomWarning",
    "tiltAngleField",
    "tiltAngleWarning",
  ];

  static values = {
    rowOptionsToLookupRows: Object,
    rowOptionsToRailOptions: Object,
  };

  ewPierSpanTypeChange(event) {
    const checkbox = event.currentTarget;
    const { value } = checkbox;
    const { checked } = checkbox;
    const customChecked = (value === "custom" && checked) || (value === "max" && !checked);
    animate.toggle(this.customEwPierSpanFieldContainerTarget, customChecked);
  }

  updateMaxSpan = (_event) => {
    if (this.hasDiagonalBracingCheckboxTarget) {
      const rows = this.rowsForMaxSpanLookup;

      const diagonalBracing = this.diagonalBracingCheckboxTarget.checked;
      const maxSpanLookup = this.projectMaxSpans.find(
        (pms) => pms.rows === rows && pms.diagonal_bracing === diagonalBracing,
      );
      const maxSpan = maxSpanLookup !== undefined ? maxSpanLookup.max_span : "?";
      this.maxSpanDisplayTarget.innerHTML = maxSpan;
    }
  };

  updateRowOptions = (_event) => {
    const rows = Number.parseInt(this.rowsFieldTarget.value);
    const railOptionsSelect = this.railOptionsFieldTarget;
    const currentRailOption = railOptionsSelect.value;

    const railOptions = this.rowOptionsToRailOptionsValue[rows];
    const selectOptions = railOptions["select_options"];
    const disabledOption = railOptions["disabled"][0];

    const optsList = selectOptions.map((item) => {
      const [label, value] = item;
      const disabled = value === disabledOption;
      const selected = value === currentRailOption;
      return [label, value, disabled, selected];
    });
    addOptionsToSelect(optsList, railOptionsSelect);

    this.showHideRailOptionWarning(disabledOption);
  };

  updateRailWarnings = (_event) => {
    const rows = Number.parseInt(this.rowsFieldTarget.value);
    const railOptionsSelect = this.railOptionsFieldTarget;
    const currentRailOption = railOptionsSelect.value;

    const railOptions = this.rowOptionsToRailOptionsValue[rows];
    const disabledOption = railOptions["disabled"][0];

    this.showHideRailOptionWarning(currentRailOption === disabledOption);

    const currentOptionLabel = railOptions.select_options.find((option) => option[1] === currentRailOption)[0];
    this.showHideRailCustomWarning(this.endsWithAsterisk(currentOptionLabel));
  };

  updateTiltAngleWarning() {
    if (!this.hasTiltAngleWarningTarget) {
      return;
    }

    const tiltAngle = Number.parseInt(this.tiltAngleFieldTarget.value);
    if (tiltAngle === 45) {
      animate.show(this.tiltAngleWarningTarget);
    } else {
      animate.hide(this.tiltAngleWarningTarget, { fadeOut: false });
    }
  }

  showHideRailOptionWarning(showWarning) {
    if (showWarning) {
      animate.show(this.railOptionsWarningTarget);
    } else {
      animate.hide(this.railOptionsWarningTarget, { fadeOut: false });
    }
  }

  showHideRailCustomWarning(showWarning) {
    if (showWarning) {
      animate.show(this.railCustomWarningTarget);
    } else {
      animate.hide(this.railCustomWarningTarget, { fadeOut: false });
    }
  }

  endsWithAsterisk(str) {
    return str.length > 0 && str.charAt(str.length - 1) === "*";
  }

  get rowsForMaxSpanLookup() {
    const rows = Number.parseInt(this.rowsFieldTarget.value);
    return this.rowOptionsToLookupRowsValue[rows];
  }

  get projectMaxSpans() {
    return this.subArraysController.projectMaxSpans;
  }

  get subArraysController() {
    const identifier = "gb--sub-arrays";
    const controllerElement = document.querySelector(`[data-controller='${identifier}']`);
    return this.application.getControllerForElementAndIdentifier(controllerElement, identifier);
  }
}
