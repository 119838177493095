import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import axios from "axios";
import * as api from "../../helpers/api";

export default class extends Controller {
  static values = {
    projectPrefix: String,
  };

  static targets = [
    "orientationRailsSpansAndForcesField",
    "landscapeViewContainer",
    "setPortraitButton",
    "portraitViewContainer",
    "setLandscapeButton",
    "setOrientationUrl",
    "railTypeSelect",
  ];

  setCurrentView(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const currentView = button.dataset.viewName;

    this.orientationRailsSpansAndForcesFieldTarget.value = currentView;

    this.updateOrientation(currentView);

    this.toggleButtonActive(button, true);

    if (currentView === "landscape") {
      if (this.hasPortraitViewContainerTarget) {
        this.portraitViewContainerTarget.style.display = "none";
      }

      animate.show(this.landscapeViewContainerTarget);

      if (this.hasSetPortraitButtonTarget) {
        this.toggleButtonActive(this.setPortraitButtonTarget, false);
      }
    } else {
      if (this.hasLandscapeViewContainerTarget) {
        this.landscapeViewContainerTarget.style.display = "none";
      }

      animate.show(this.portraitViewContainerTarget);

      if (this.hasSetLandscapeButtonTarget) {
        this.toggleButtonActive(this.setLandscapeButtonTarget, false);
      }
    }
  }

  updateOrientation(orientation) {
    axios({
      method: "PATCH",
      url: this.setOrientationUrlTarget.dataset.value,
      data: { orientation },
      headers: api.defaultHeaders(),
    });
  }

  toggleButtonActive(button, bool) {
    button.closest("li").classList[bool ? "add" : "remove"]("active");
  }
}
