export const ARRAY_CELL_DATA_TYPE = "ArrayCell";
export const ARRAY_DATA_TYPE = "Array";
export const BOUNDING_BOX_DATA_TYPE = "BoundingBox";
export const MEASURE_DATA_TYPE = "Measure";
export const ROOF_PLANE_DATA_TYPE = "RoofPlane";
export const OBSTRUCTION_DATA_TYPE = "Obstruction";
export const OBSTRUCTION_BUFFER_DATA_TYPE = "ObstructionBuffer";
export const ROOF_SECTION_DATA_TYPE = "RoofSection";
export const SETBACK_DATA_TYPE = "Setback";
export const GUIDE_LINE_DATA_TYPE = "GuideLine";
export const GUIDE_LINES_INTERSECTION_CIRCLE_DATA_TYPE = "GuideLineIntersectionCircle";
export const EAVE_SELECTION_LINE_STRING_DATA_TYPE = "EaveSelectionLineString";
export const RULER_DATA_TYPE = "Ruler";
export const MEASURE_DRAG_HANDLE_DATA_TYPE = "MeasureDragHandle";
export const RAIL_DATA_TYPE = "Rail";
export const PANEL_DATA_TYPE = "Panel";
export const SEGMENT_DATA_TYPE = "Segment";
export const VISUAL_MARKER_DATA_TYPE = "VisualMarker";
export const THERMAL_EXPANSION_DATA_TYPE = "ThermalExpansion";
export const ADJOINMENT_DATA_TYPE = "Adjoinment";
export const CONTOUR_LEGALITY_ADJOINMENT_DATA_TYPE = "ContourLegalityAdjoinment";
export const CONTOUR_DATA_TYPE = "Contour";
