import { Collection } from "ol";
import { Modify } from "ol/interaction";
import { never as neverCondition } from "ol/events/condition";

import { pointAtFeatureVertex, ensureFeatureIsClockwise } from "../../ol-helpers";
import { MEASURE_DATA_TYPE, MEASURE_DRAG_HANDLE_DATA_TYPE } from "../../data-types";
import { calculateMeasureSlopeAdjustedDistance } from "../measure/helpers";

export default class Base {
  constructor(controller) {
    this.controller = controller;

    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.currentModifyInteraction = undefined;
  }

  add() {
    this.clearCurrentInteraction();
    this.addPolygonModifyInteraction();
    this.addMeasureModifyInteraction();
    this.controller.snapInteractionManager.refresh();
  }

  addPolygonModifyInteraction() {
    this.currentModifyInteraction = new Modify(this.polygonModifyInteractionParams);
    this.currentModifyInteraction.on("modifystart", this.modifyStart);
    this.currentModifyInteraction.on("modifyend", this.modifyEnd);
    this.map.addInteraction(this.currentModifyInteraction);
  }

  get polygonModifyInteractionParams() {
    return { features: this.selectedPolygonFeatures };
  }

  addMeasureModifyInteraction() {
    this.measureModifyInteraction = new Modify({
      features: this.selectedMeasureFeatures,
      insertVertexCondition: neverCondition,
    });
    this.map.addInteraction(this.measureModifyInteraction);
    this.measureModifyInteraction.on("modifystart", this.measureModifyStart);
    this.measureModifyInteraction.on("modifyend", this.measureModifyEnd);
  }

  measureModifyStart = (_event) => {
    const measuresVectorSource = this.mapManager.measuresVectorSource;
    const measureFeaturesArray = measuresVectorSource.getFeatures();
    measureFeaturesArray.forEach((feature) => {
      if (feature.get("dataType") === MEASURE_DRAG_HANDLE_DATA_TYPE) {
        this.controller.selectInteractionManager.measureSelectManager.removeDragHandles([feature]);
      } else {
        feature.set("dragHandles", undefined);
      }
    });
  };

  measureModifyEnd = (_event) => {
    const selectInteractionManager = this.controller.selectInteractionManager;
    const selectedMeasures = selectInteractionManager.currentSelectInteraction
      .getFeatures()
      .getArray()
      .filter((feature) => {
        return feature.get("dataType") === MEASURE_DATA_TYPE;
      });
    selectedMeasures.forEach((measureFeature) => {
      measureFeature.set(
        "slopeAdjustedDistance",
        calculateMeasureSlopeAdjustedDistance(measureFeature, this.controller),
      );
      selectInteractionManager.measureSelectManager.addDragHandle(measureFeature);
    });
  };

  get selectedPolygonFeatures() {
    const polygonFeatures = this.selectedFeatures.filter((feature) => {
      const featureDataType = feature.get("dataType");
      return (
        featureDataType && featureDataType !== MEASURE_DATA_TYPE && featureDataType !== MEASURE_DRAG_HANDLE_DATA_TYPE
      );
    });

    return new Collection(polygonFeatures);
  }

  get selectedFeatures() {
    return this.controller.selectInteractionManager.selectedFeatures;
  }

  get selectedMeasureFeatures() {
    const measureFeatures = this.selectedFeatures.filter((feature) => {
      return feature.get("dataType") === MEASURE_DATA_TYPE && feature.get("dataType") !== MEASURE_DRAG_HANDLE_DATA_TYPE;
    });

    return new Collection(measureFeatures);
  }

  remove() {
    this.clearCurrentInteraction();
  }

  refresh() {
    this.add();
  }

  modifyStart = (event) => {
    const { features } = event;

    if (!features) return;
    features.forEach((feature) => this.modifyStartForFeature(feature));
  };

  modifyEnd = (event) => {
    const { features } = event;

    if (!features) return;

    features.forEach((feature) => {
      const selectedVertex = feature.get("selectedVertexCoordinates");
      if (selectedVertex) {
        if (!pointAtFeatureVertex(feature, selectedVertex)) {
          feature.unset("selectedVertexCoordinates");
        }
      }

      ensureFeatureIsClockwise(feature);
      this.modifyEndForFeature(feature);
    });

    this.controller.markDirty();
  };

  modifyStartForFeature(_feature) {
    // override in sub class
  }

  modifyEndForFeature(_feature) {
    // override in sub class
  }

  clearCurrentInteraction() {
    if (this.currentModifyInteraction) {
      this.currentModifyInteraction.un("modifyend", this.modifyEnd);
      this.map.removeInteraction(this.currentModifyInteraction);
      delete this.currentModifyInteraction;
      this.currentModifyInteraction = undefined;
    }

    if (this.measureModifyInteraction) {
      this.map.removeInteraction(this.measureModifyInteraction);
      delete this.measureModifyInteraction;
      this.measureModifyInteraction = undefined;
    }
  }
}
